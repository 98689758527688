
// fancybox
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

Fancybox.bind('[data-fancybox="gallery"]', {});

Fancybox.bind("[data-fancybox]", {
    contentClick: "iterateZoom",
    Images: {
        Panzoom: {
            maxScale: 2,
        },
    },
});