if (document.querySelectorAll('[data-component="navbar-toggle"]').length > 0) {
    document.addEventListener('DOMContentLoaded', function () {

        (function ($) {
            // ON SCROLL
            window.Helper = (typeof Helper !== "undefined") ? Helper : {};
        
            Helper.scroll_to = function(el, top){
                $ = jQuery;
                top = top || 0;
        
                $('html, body').animate({
                    scrollTop: $(el).offset().top + top
                }, 250);
            };
        
            $('[data-toggle="scroll"]').on('click', function(e){ 
                e.preventDefault();
                
                let $this = $(this);
                let offset = $($this).data('offset') || 0;
        
                $('.main').fadeIn(250, function() {});
                
                Helper.scroll_to($($($this).data('href')), offset);

                document.querySelector('#navbarNavDropdown').classList.remove('show');
            });
        
            $(window).scroll(function() {
                var scrollDistance = $(window).scrollTop();
            
                // Assign active class to nav links while scolling
                $('.section').each(function(i) {
                    if ($(this).position().top <= scrollDistance) {
                        $('.navbar-menu__list div a.active').removeClass('active');
                        $('.navbar-menu__list div a').eq(i).addClass('active');
                    }
                });
            }).scroll();
        })(jQuery);

        let navbarToggle = document.querySelector('.navbar-toggle');
        let navbarCollapse = document.querySelector('#navbarNavDropdown');
        let navbarExpanded = navbarCollapse.getAttribute('aria-expanded');

        navbarToggle.addEventListener('click', function (e) {
            e.preventDefault();
            navbarCollapse.classList.toggle('show');

            if (navbarExpanded == 'true') {
                navbarExpanded = 'false';
            } else {
                navbarExpanded = 'true';
            }

            navbarCollapse.setAttribute('aria-expanded', navbarExpanded)
        })
    })
}